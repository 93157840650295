.call-stats-widget {
  text-transform: uppercase;

  .title {
    color: $darkgrey;
    font-weight: normal;
    margin-right: 0.5rem;
  }

  i {
    color: $lightgrey;
    opacity: 0.5;
  }

  .progress {
    border-radius: 40px;
    margin: 29.1px 0px;

    .progress-bar-answered,
    .bg-success {
      background: linear-gradient(
        to right,
        lighten($success, 10%),
        $success,
        darken($success, 10%)
      );
    }
    .progress-bar-unanswered {
      background: linear-gradient(
        to right,
        lighten($danger, 10%),
        $danger,
        darken($danger, 10%)
      );
    }
    .progress-bar-average,
    .bg-primary {
      background: linear-gradient(to right, $accent, $primary);
    }
    .progress-bar-max,
    .bg-warning {
      background: $orange-gradient;
    }
  }
}
