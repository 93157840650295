.phonebook-widget {
  overflow: hidden;

  p {
    color: #646464;
    font-size: small;
    font-weight: normal;
    margin-bottom: 0;
  }

  // For consistency with CardHeader
  .header-text {
    color: $primary;
    font-weight: 400;
    font-size: 1.5rem;
  }

  .contact-editable {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .form-group {
    margin-top: 1rem;
  }

  .input-group {
    flex-wrap: nowrap;

    .input-group-append {
      .input-group-text {
        border: none;
        background: $orange-gradient;
        color: $white;
      }
    }
  }

  /* Async select hacks */
  .rbt {
    min-width: 80%;
  }
  .select-popover-style {
    width: 0;
    margin: 0px;
  }
}
