//button {
//  @extend .btn;
//}

.btn-transparent {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

.btn {
  [class^='icon-'],
  [class*=' icon-'] {
    display: inline-block;
    margin-top: -2px;
    vertical-align: middle;
  }
  border-radius: 50em;
  font-weight: bold;
}

.btn-pill {
  border-radius: 50em;
}

.btn-square {
  border-radius: 5px;
}

@each $color, $value in $theme-colors {
  .btn-ghost-#{$color} {
    @include button-ghost-variant($value);
  }
}
