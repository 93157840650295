.dss-header {
  display: flex;
  justify-content: space-between;

  .btn {
    margin-right: 0.5rem;
  }
}

.dss-flex {
  display: flex;
  flex-wrap: wrap;

  .col-xl-6 {
    padding: 0.5rem;
  }

  .dss-item {
    background-color: $inputFieldBgColor;
    border: 1px solid $lightgrey;
    display: flex;
    height: 4.5rem;
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 7px 7px 10px #00000009;
    align-items: center;
    cursor: pointer;

    .dss-clickable {
      width: calc(100% - 4.5rem);
      display: flex;
      align-items: center;
    }

    .btn {
      height: 4.5rem;
      width: 4.5rem;
      border-radius: 0;
      border-left-width: 0;
      border-right-width: 1px;

      i {
        font-size: 2rem;
      }
    }

    .label {
      color: $blue;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1rem;
    }

    .value {
      color: $primary;
      font-size: 1.25rem;
    }

    .clear {
      display: none;
    }

    &:hover {
      .clear {
        display: block;
      }
    }

    .content {
      margin-left: 1rem;
      margin-right: auto;

      .dss-disabled {
        color: $mediumgrey;
      }
    }
  }

  .reverse {
    flex-direction: row-reverse;

    .dss-clickable {
      flex-direction: row-reverse;
    }

    .btn {
      border-left-width: 1px;
      border-right-width: 0;
    }

    .label {
      text-align: end;
    }

    .content {
      margin-left: auto;
      margin-right: 1rem;
      text-align: right;
    }
  }

  .disabled {
    .dss-clickable {
      width: 100%;
    }

    .btn {
      color: $lightgrey !important;
      border-color: $lightgrey !important;
      background: $inputFieldBgColor !important;
    }

    .label {
      text-align: end;
    }

    .clear {
      margin-left: 0;
      margin-right: auto;
    }

    &:hover {
      .clear {
        display: none;
      }
    }
  }

  .dragOver {
    border: 3px dashed $lightgrey !important;

    .dss-clickable,
    .btn,
    div {
      pointer-events: none;
    }
  }
}

.dss-button-next {
  width: 100%;
  height: 100%;
}
