// Variable overrides

$primary: #002583;
$orange: #f17725;
$secondary: #eee;
$danger: #f2453d;
$accent: #0091ec;

$black: #242424;
$white: #ffffff;
$darkgrey: #646464;
$mediumgrey: #969696;
$lightgrey: #d8d8d8;
$xlightgrey: #f5f5f5;
$bluegrey: #73818f;

$ok: #8dc252;
$warning: #fd9827;

$orange-gradient: linear-gradient(to right, #f6ac3b, #f07825);

$inputFieldBgColor: #f2f9fe;

// Header variables
$navbar-height: 145px !default;
$navbar-brand-bg: #f2f9fe;

// Main Menu style
$sidebar-bg: #052781;
$sidebar-nav-link-padding-x: 0.75rem !default;
$sidebar-nav-link-padding-y: 1rem !default;
$sidebar-nav-icon-font-size: 1.5rem;
$sidebar-nav-link-color: #fff !default;
$sidebar-nav-link-icon-color: #fff !default;
$sidebar-nav-link-bg: transparent !default;
$sidebar-nav-link-active-color: #fff;
$sidebar-nav-link-active-icon-color: #fff;
$sidebar-nav-icon-bg: rgba(0, 37, 131, 0.5);
$sidebar-nav-icon-hover-bg: rgba(0, 37, 131, 0.7);
$sidebar-nav-icon-active-bg: rgba(0, 37, 131, 0.9);

// Nav Tabs
$nav-tabs-link-color: $primary;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-hover-color: $white;
$nav-tabs-link-active-bg: $primary;

// Card variables
$card-border-radius: 2rem;

// Tooltip variables
$tooltip-color: $primary;
$tooltip-bg: #dde9f6;

// Footer variables
$footer-color: #4dbd74;
$footer-bg: #fff;
$footer-height: 0px;
$modal-footer-height: 75px;
$modal-footer-bg: #f2f9fe;

// Nav variables
$tab-content-bg: none;

// Dropdown variables
$dropdown-link-active-color: $black;

// Font variables
$font-family-base: Roboto,
  // Fallbacks
    'Helvetica Neue',
  Arial, 'Noto Sans', sans-serif,
  // Emoji fonts
    'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// Checkboxes
$checkbox-color-generic: $accent;
$checkbox-color-table: $primary;
$checkbox-focus-effect-generic: 0 0 0 3px white,
  0 0 0 4px $checkbox-color-generic;

// Bootstrap checkboxes (used on tables)
$custom-control-indicator-size: 1.5rem;
$custom-control-indicator-border-color: $checkbox-color-table;
$custom-control-indicator-focus-border-color: $checkbox-color-table;
$custom-control-indicator-active-bg: $checkbox-color-table;
$custom-control-indicator-checked-bg: $checkbox-color-table;
$custom-control-indicator-border-width: 2px;
$custom-checkbox-indicator-border-radius: 4px;
$custom-control-indicator-box-shadow: 0.2px 0.2px 3px rgba(0, 0, 0, 0.15);
$custom-control-indicator-focus-box-shadow: 0 0 0 3px white,
  0 0 0 4px $custom-control-indicator-focus-border-color;

// placeholder
$placeholder-color: #9badd6;

// Misc
$dashboard-configure-checkbox-color: #8fc93a;
$code-color: $black;
