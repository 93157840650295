.srd-node {
  position: absolute;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  user-select: none;
  cursor: move;
  pointer-events: all;

  &--selected {
    > * {
      border-color: #6d6d6d !important;
    }
  }
}
