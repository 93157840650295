.aside-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Accent Aside widget color */
.bg-accent {
  background-color: $accent;
  border-color: $accent;
}

.bg-orange-gradient,
.btn-orange-gradient {
  background: $orange-gradient;
  border-color: #f6ac3b !important;
}

.aside-icon {
  opacity: 0.5;
  z-index: 0;
  position: absolute;
  right: 1.25rem;
}

.aside-devices {
  .aside-icon {
    color: $lightgrey !important;
  }
}

.aside-help, .aside-webinar {
  .h4 {
    color: $primary;
  }

  .aside-icon {
    color: $lightgrey !important;
  }
}

