/* Custom checkboxes */
.checkbox-simple {
  line-height: 1rem;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:focus ~ label::before {
      box-shadow: $checkbox-focus-effect-generic;
    }

    &:checked ~ label::before {
      background: $checkbox-color-generic;
      background-image: $custom-checkbox-indicator-icon-checked;
      color: white;
      background-size: 0.75rem;
      background-repeat: no-repeat;
      background-position: center;
      border: unset;
    }

    &:checked ~ label:hover::before {
      background-color: darken($color: $checkbox-color-generic, $amount: 10);
    }
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;

    &::before {
      border-radius: 3px;
      content: '';
      display: inline-block;
      position: relative;
      width: 1rem;
      height: 1rem;
      margin-right: 5px;
      border: 1px solid $mediumgrey;
      transition: background-color 0.25s, border 0.25s, box-shadow 0.25s;
    }

    &:active {
      &::before {
        background-color: darken($color: $checkbox-color-generic, $amount: 10);
      }
    }

    &:hover {
      text-decoration: underline;

      &::before {
        background-color: darken($color: white, $amount: 10);
      }
    }
  }

  &:active {
    label::before {
      background-color: $checkbox-color-generic;
      border: unset;
    }

    input:checked ~ label::before {
      background-color: $checkbox-color-generic;
      background-image: none;
    }
  }
}
