.srd-default-port {
  cursor: pointer;
  $p: &;

  display: flex;
  margin-top: 1px;

  > * {
    align-self: center;
  }

  &__name {
    padding: 0 5px;
  }

  &--out {
    justify-content: flex-end;

    #{$p}__name {
      justify-content: flex-end;
      text-align: right;
    }
  }
}
