.time-schedule-widget {
  .card-header {
    display: inline-block;

    .rbt {
      width: 20%;
      float: right;
      margin-right: 0.5rem;
      text-transform: initial;
    }

    .btn {
      float: right;
    }
  }

  .btn-orange-gradient {
    background: $orange-gradient;
    border-color: #f6ac3b !important;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
  }

  .days-col {
    display: inline-block;
    width: 5%;
  }

  .bars-col {
    display: inline-block;
    width: 95%;

    .bars-header {
      width: 100%;

      .time-indicator {
        display: inline-block;
        text-align: center;
      }
    }

    hr {
      margin: 5px 0px;
      background: $xlightgrey !important;
    }

    .progress {
      background-color: $secondary;

      .progress-bar.bg-accent {
        border-radius: 0.25rem;
        height: 0.4rem;
        margin: auto 0;
      }
    }
  }

  .text-accent {
    color: $accent;
  }

  .no-data:hover {
    text-decoration: none;
  }

  .time-schedule {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: -2;

    small {
      text-transform: uppercase;
      font-weight: bold;
      color: #777;
    }
  }
}
