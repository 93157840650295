/* CoreUI custom layout Start */
.fa-avatar {
  font-size: 24px;
}

.diagram-node-sm {
  width: 160px !important;
}

.diagram-node-md {
  width: 260px !important;
}

.diagram-node-lg {
  width: 300px !important;
}

.diagram-node-xl {
  width: 320px !important;
}

.diagram-node-xxl {
  width: 380px !important;
}

.popover-details-button {
  line-height: 40px;
  font-size: 14px;
}

.simple-dial-modal {
  min-width: 1000px;
}

.simple-dial-tiers-data {
  width: 220px;
  height: 60px;
  white-space: normal;
  overflow-y: auto;
}

.accordion-input-title {
  background: none;
  border: none;
}

.accordion-input-title:focus {
  outline: none !important;
  border-bottom: 1px solid #c2cfd6;
}

// TBR block days picker styles
.time-based-routing-data {
  .weekday-picker {
    width: 350px;
  }

  .timerange-picker {
    width: 500px;
  }
  tr td {
    vertical-align: middle;
  }

  .btn-group {
    margin-top: 1rem;
    .btn {
      text-transform: uppercase;
      background-color: unset;

      &:hover:not(.active) {
        color: $primary;
        border-color: $primary;
      }
    }
  }
}

.app-switch-spacing {
  margin-top: 20px;
}

/* CoreUI custom layout End */

.diagram-container {
  margin: 0px;
}

.diagram-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* Calculate diagram height based on viewport - (header + footer + 120px) */
  height: calc(100vh - 285px);
  padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;

  .content {
    display: flex;
    flex-grow: 1;

    .diagram-layer {
      position: relative;
      flex-grow: 1;

      .srd-demo-canvas {
        height: 100%;
        min-height: 300px;
        background-color: #f0f3f5;
        border-radius: 30px;
      }
    }

    .tray {
      width: 190px;
      min-height: 300px;
      height: 100%;
      background: #ffffff;
      flex-grow: 0;
      flex-shrink: 0;

      .tray-item {
        text-align: left;
        color: white;
        padding: 5px;
        margin: 10px 10px;
        border: solid 1px;
        border-radius: 20px;
        cursor: pointer;
        // Workaround for draggable rounded items having white corners on webkit
        opacity: 0.99;

        .flex-row {
          display: flex;
          align-items: center;
        }

        &.toggle {
          text-align: center;
          margin-bottom: 0px;
        }

        &.blue-title {
          color: #20a8d8;

          &:hover {
            color: lighten($color: #2fb2e0, $amount: 15);
          }
        }

        &.blue {
          color: #20a8d8;
          outline-color: #20a8d8;

          &:hover {
            background-color: #20a8d8;
            color: #ffffff;
          }
        }

        &.purple-title {
          background-color: #ffffff;
          color: #8f00c9;

          &:hover {
            color: lighten($color: #a700eb, $amount: 15);
          }
        }

        &.purple {
          color: #8f00c9;
          outline-color: #8f00c9;

          &:hover {
            background-color: #8f00c9;
            color: #ffffff;
          }
        }

        &.green-title {
          background-color: #ffffff;
          color: #24a121;

          &:hover {
            color: lighten($color: #29b725, $amount: 15);
          }
        }

        &.green {
          color: #24a121;
          outline-color: #24a121;

          &:hover {
            background-color: #24a121;
            color: #ffffff;
          }
        }

        &.orange-title {
          background-color: #ffffff;
          color: #f17725;

          &:hover {
            color: lighten($color: #f17725, $amount: 15);
          }
        }

        &.orange {
          color: #f17725;
          outline-color: #f17725;

          &:hover {
            background-color: #f17725;
            color: #ffffff;
          }
        }

        &.yellow-title {
          background-color: #ffffff;
          color: #f5af36;

          &:hover {
            color: lighten($color: #f6b545, $amount: 15);
          }
        }

        &.yellow {
          color: #f5af36;
          outline-color: #f5af36;

          &:hover {
            background-color: #f5af36;
            color: #ffffff;
          }
        }

        .tray-icon {
          font-size: 8px;
          margin: 0px 8px;

          i {
            width: 20px;
          }
        }

        .tray-title {
          font-size: 12px;
        }
      }
    }
  }
}

.btn-no-click {
  cursor: default !important;
  border-radius: 0px !important;
  pointer-events: none;

  & b {
    margin-left: 10px;
  }
}

.tier-input-group {
  float: left;
  width: auto;

  .input-group-text {
    background: $inputFieldBgColor;
    color: $primary;
    border-color: $gray-200;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .accordion-input-title {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background: $inputFieldBgColor;
    border: 1px solid $gray-200;

    &:focus {
      background-color: $white;
      border-color: $accent;
    }
  }
}

.btn-add-tier {
  margin-top: 20px;
}

.fade:not(.show) {
  display: none !important;
}

.help-msg {
  .card-header-action {
    color: #333 !important;
  }

  .card-body {
    background: #f0f3f5;
    color: #000;
  }
}

.simple-dial-popover {
  .popover {
    max-width: 450px !important;
  }
  .popover-body {
    padding: 0.75rem, 0.25rem;
    .text-column {
      width: 90px;
      overflow: hidden;
    }
    .number-column {
      witdh: 50px;
      overflow: hidden;
    }
  }
}

.tier-container {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  padding: 0.4rem;
  color: #23282c;
  margin-bottom: 0.3rem;
}

.tier-popover-title {
  display: block;
  padding: 2px 0px;
  width: 100%;
}
.tier-popover-title i {
  margin-right: 4px;
}

.tier-popover-data span {
  display: block;
  padding: 2px 0px;
  width: 100%;
}

.popover-md {
  min-width: 200px;
  .popover-body {
    padding: 0.75rem, 0.25rem;
  }
}

.editable-block-content {
  cursor: pointer;
  p {
    margin: 0;
    padding: 0px 0px 0px 3px;
  }
  div button {
    margin: 0;
    padding: 0px 0px 0px 0px;
  }
}
