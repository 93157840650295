.app-header {
  position: relative;
  flex-direction: row;
  height: 100px;
  padding: 0;
  margin: 0;
  background-color: $navbar-bg;
  @include borders($navbar-border);
  border-color: $xlightgrey;

  .navbar-brand {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ($navbar-brand-width + 20px);
    height: 100px;
    padding: 0px 20px 0px 0px;
    margin-right: 0;
    /* background-color: $inputFieldBgColor; */
    background-color: $navbar-brand-bg;
    @include borders($navbar-border);
    border-color: $xlightgrey;

    .navbar-brand-minimized {
      display: none;
    }
  }

  .navbar-toggler {
    min-width: 50px;
    padding: $navbar-toggler-padding-y 0;

    &:hover .navbar-toggler-icon {
      background-image: $navbar-toggler-icon-hover;
    }
  }

  .navbar-toggler-icon {
    margin-left: 15px;
    height: 23px;
    background-image: $navbar-toggler-icon;
  }

  .navbar-nav {
    flex-direction: row;
    align-items: center;
    height: 70%;
  }

  .nav-item {
    position: relative;
    min-width: 50px;
    margin: 0;
    text-align: center;

    button {
      margin: 0 auto;
    }

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      background: 0;
      border: 0;

      .nav-icon {
        width: 40px;
        height: 25px;
        display: block;
        margin: 0 20px;
        font-size: 1.5em;
      }

      .badge {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: 0;
      }

      > .img-avatar {
        height: $navbar-height - 20px;
        margin: 0 10px;
      }
    }
  }

  .dropdown-menu {
    padding-bottom: 0;
    line-height: $line-height-base;
  }

  .dropdown-item {
    min-width: 180px;

    a {
      color: $dropdown-link-color !important;
    }
  }
}

// .navbar-brand {
//   color: $navbar-active-color;
//
//   @include hover-focus {
//     color: $navbar-active-color;
//   }
// }

.navbar-nav {
  .nav-link {
    color: $navbar-color;

    @include hover-focus {
      color: $navbar-hover-color;
    }
  }

  .open > .nav-link,
  .active > .nav-link,
  .nav-link.open,
  .nav-link.active {
    @include plain-hover-focus {
      color: $navbar-active-color;
    }
  }
}

.navbar-divider {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-fullscreen-image {
  display: none;
}

.navbar-divider-fancy-border {
  display: block;
  margin-left: -20px;
  width: 20px;
  height: 100%;
  background-color: $white;
  border-top-left-radius: 20px;
}

@include media-breakpoint-up($breakpoint-mobile-up) {
  .brand-minimized {
    .app-header {
      .navbar-brand {
        width: $navbar-brand-minimized-width;
        background-color: $navbar-brand-minimized-bg;
        @include borders($navbar-brand-minimized-border);

        .navbar-brand-full {
          display: none;
        }

        .navbar-brand-minimized {
          display: block;
        }
      }
    }
  }
}
