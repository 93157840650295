.srd-node-layer {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
