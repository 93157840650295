.srd-default-link {
  @keyframes dash {
    from {
      stroke-dashoffset: 24;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  path {
    fill: none;
    stroke: rgba(32, 168, 216, 0.8);
    pointer-events: all;
  }

  &--path-selected {
    stroke: rgb(32, 168, 216) !important;
    stroke-dasharray: 10, 2;
    animation: dash 1s linear infinite;
  }

  &:hover &__label {
    display: block;
  }

  &__label {
    display: none;
    width: 100%;
    height: 100%;
    overflow: visible !important;
    pointer-events: none;

    > div {
      position: absolute;
      display: inline-block;
    }
  }

  &__point {
    fill: rgba(32, 168, 216, 0.8);
  }

  &--point-selected {
    fill: rgb(32, 168, 216);
  }
}
