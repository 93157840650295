.badge-pill {
  border-radius: $badge-pill-border-radius;
}

.badge {
  border-radius: $badge-pill-border-radius;
  padding: 4px 8px;
  color: $white;
}

.badge-accent {
  color: $white;
  background-color: $accent;
  border-color: $accent;
}
