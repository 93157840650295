.trends-widget {
  text-transform: uppercase;

  h6 {
    color: $darkgrey;
    font-weight: normal;
  }

  h2 {
    padding: 10px 0;
    color: $primary;
    font-weight: lighter;
    text-align: center;
  }

  .badge {
    font-size: small;
    vertical-align: middle;
  }

  .form-group:not(:first-child) {
    border-top: 1px solid $lightgrey;
    padding-top: 20px;
  }
}
