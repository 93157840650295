.srd-default-label {
  pointer-events: all !important;
  cursor: pointer;
  display: inline-block;
  background: none;
  border: none;
  color: rgba(194, 207, 214, 0.8);
  font-size: 1.2rem;
  padding: 5px;
}
