.callflow-toggles-widget {
  .card-header {
    font-size: 21px;
    font-weight: 500;
    line-height: 25px;
    padding-bottom: 0;
  }

  .callflow-card {
    .card-header {
      color: unset;
      background-color: $xlightgrey;
      padding-bottom: 0.75rem;
      font-size: 20px;
      line-height: 21px;
      font-weight: 400;
      text-transform: none;
    }

    .card-body {
      .toggle-label {
        color: unset;
        padding-left: 20px;
        margin: 8px 0px;
        text-transform: none;
        line-height: 21px;
      }

      i {
        margin: 0.6rem 0.25rem;
        color: $accent;
        cursor: pointer;
      }

      .option-label {
        padding-left: 40px;
        margin: auto;
      }
    }
  }
}
