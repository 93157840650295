.opening-hours-widget {
  width: 100%;
  color: $mediumgrey;
  margin-top: 20px;

  /* Day/Hour style */
  .day {
    margin-bottom: 10px;
  }

  .hours,
  .day {
    display: flex;

    .hour {
      width: calc(100% / 12);
    }
  }

  .day .hour:not(:first-child) {
    position: relative;

    &:before {
      content: '';
      background-color: $xlightgrey;
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      height: 10px;
    }

    &.active:after {
      content: '';
      background-color: $accent;
      position: absolute;
      top: 13px;
      left: 0;
      width: 100%;
      height: 4px;
    }

    &.half:after {
      width: 50%;
    }

    &.start:after {
      right: 0;
      left: auto;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &.end:after {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &.appointment:after {
      background-color: $primary;
    }
  }

  /* Legend style */
  .legend {
    margin-left: calc(100% / 12);
    margin-top: 20px;

    .item {
      position: relative;
      padding-left: 30px;
    }

    .item:before {
      background-color: $accent;
      content: '';
      width: 20px;
      height: 4px;
      position: absolute;
      top: 10px;
      left: 0;
    }

    .appointment:before {
      background-color: $primary;
    }
  }
}
