.nav-tabs {
  font-size: 1rem;
  border-bottom: 2px solid $lightgrey;

  .nav-link,
  .btn {
    border: none;
    background-color: unset !important;
    color: $nav-tabs-link-color !important;
    opacity: 0.5;

    &:hover {
      cursor: pointer !important;
      text-decoration: underline !important;
    }
  }

  .nav-link.active,
  .btn-group {
    opacity: 1;
    border-bottom: 2px solid $accent;

    .btn {
      opacity: inherit;
    }
  }
}

.tab-content {
  margin-top: -1px;
  background: $tab-content-bg;
  //border: 1px solid $lightgrey;
  //@include border-radius(0 0 $card-border-radius $card-border-radius);

  .tab-pane {
    padding: $spacer;
  }
}

.card-block {
  .tab-content {
    margin-top: 0;
    border: 0;
  }
}

.nav-fill {
  .nav-link {
    background-color: #fff;
    border-color: $border-color;
  }
  .nav-link + .nav-link {
    margin-left: -1px;
  }
  .nav-link.active {
    margin-top: -1px;
    // margin-left: 0;
    border-top: 2px solid $primary;
  }
}

// Right-to-Left Support
*[dir='rtl'] {
  .nav {
    padding-right: 0;
  }
}
