.table-outline {
  border: 1px solid $table-border-color;

  td {
    vertical-align: middle;
  }
}

.table-align-middle {
  td {
    vertical-align: middle;
  }
}

.table-clear {
  td {
    border: 0;
  }
}

thead {
  text-transform: uppercase;
  color: $mediumgrey;

  th {
    font-weight: normal;
  }

  // Tooltip & sort icons
  .btn,
  .tooltip-symbol:after {
    color: inherit;
  }
}

.table {
  th {
    border-top: none;
    border-bottom: $table-border-width solid $table-border-color;
  }

  thead th {
    border-bottom-width: $table-border-width;
  }

  td,
  tbody + tbody {
    border-top: $table-border-width dashed $table-border-color;
  }
}
