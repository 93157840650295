.app-footer {
  position: relative;
  @include borders($footer-borders);
  border-color: $xlightgrey;

  .footer-body {
    display: flex;
    flex-wrap: wrap;
    padding: 0 $spacer;
    color: $footer-color;
    background: $footer-bg;
    align-items: center;
    height: $footer-height;
  }

  .footer-collapse {
    display: flex;
    flex-wrap: wrap;
    padding: 0 $spacer;
    color: $footer-color;
    background: $footer-bg;
    align-items: left;
    justify-content: center;
    text-align: center;
    height: 300px;
    overflow-y: auto;

    label {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .btn {
      margin-top: 1.5rem;
    }
  }

  .footer-toggle-icon {
    position: absolute;
    width: 40px;
    height: 20px;
    right: 20px;
    top: -20px;
    background-color: #fff;
    color: $footer-color;
    text-align: center;
    font-size: 25px;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    border: solid 1px $xlightgrey;
    border-bottom: 0;

    &:hover {
      cursor: pointer;
    }
  }
}
