// Here you can add other styles

/* Stupid clouds */
.app-background {
  background-image: url('../assets/img/background.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom;
}

.app-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  background-color: #fff;
  align-items: center;

  img {
    height: 11vh;
  }
}

/* react-bootstrap-typeahead dirty hacks start */
.rbt-input-main {
  width: 100% !important;
}

.rbt-menu.dropdown-menu {
  z-index: 1060;

  .active {
    background-color: $inputFieldBgColor;
  }

  .dropdown-item {
    border: none;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
  background-color: #f86c6b;
}

/* react-bootstrap-typeahead dirty hacks end */

/* Login page */
.login-page {
  min-height: 100vh;
}

.login-page-content {
  width: 400px;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  /* Login page width on mobile devices */
  .login-page-content {
    width: 100vw;
  }
}

.login-page-logo {
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}

.page-link:hover {
  cursor: pointer;
}

.cdr-filter-input-flatpickr {
  width: 150px !important;
}

/* Main Modal style */
.mdl-wpr-ext-width {
  min-width: 1200px !important;
}
.modal-wrapper {
  position: absolute;
  margin: 0;
  top: 0px;
  right: 0px;
  bottom: 0px;
  min-width: 800px;

  .modal-content {
    position: relative;
    height: 100vh;
    border: none;
    border-radius: unset;

    .modal-header {
      position: absolute;
      width: 100%;
      height: $navbar-height;
      top: 0;
      right: 0;
      color: $primary;
      background-color: $white;
      border-bottom: none;
      padding: 1.75rem 1.75rem 1.75rem 1.75rem;

      h5 {
        font-size: 2rem;
        font-weight: 300;
      }

      .close {
        font-size: 40px;
        font-weight: 100;
        color: inherit;
      }
    }

    .modal-body {
      position: absolute;
      top: ($navbar-height);
      bottom: ($modal-footer-height);
      width: 100%;
      overflow-y: auto;

      /* Modal card styles */
      .card:not(.card-show) {
        border-radius: 0px;
        border-color: $white;
        box-shadow: unset;
      }

      .card:not(:first-child):not(.card-show) {
        border-top: 1px solid $lightgrey;
        padding-top: 20px;
      }

      .form-group {
        /* Inline switch & title */
        .switch-primary + .modal-setting-title {
          display: unset;
          width: unset;
        }

        /* time range slider styles */
        .input-range__label {
          color: #5c6873;
          width: 100%;
          text-align: center;
          font-family: inherit;

          .form-control {
            min-width: 62px !important;
            margin: 0 0.5rem;
            display: inline;
          }
        }

        .input-range__slider {
          background: $accent;
          border: 1px solid $accent;
        }

        .input-range__track {
          background: $gray-200;
        }

        .input-range__track--active {
          background: $accent;
        }
      }
    }

    .modal-footer {
      position: absolute;
      width: 100%;
      height: $modal-footer-height;
      right: 0;
      bottom: 0;
      background-color: $modal-footer-bg;
      justify-content: space-between;
      padding: 1rem 3.2rem;

      .btn {
        text-transform: uppercase;
        box-shadow: 0.2px 0.2px 8px $lightgrey;
      }
    }
  }
}

.card-footer {
  .btn {
    box-shadow: 0.2px 0.2px 8px $lightgrey;
  }
}

.textbox-button:hover {
  cursor: pointer;
}

.flatpicker-button {
  padding: 0px 10px 0px 0px;
  border-right: 1px solid #c8ced3;
}

.flatpicker-button:last-child {
  padding: 0px 0px 0px 10px;
  border-right: none;
}

.flatpicker-button:hover {
  cursor: pointer;
}

.input-field-error {
  background-color: #f86c6b !important;
  color: #fff;

  &:focus {
    background-color: #f86c6b !important;
    color: #fff;
  }

  option {
    background-color: #fff;
    color: #5c6873;
  }
}

.table-data-error {
  background-color: #f86c6b;
}

.dnd-table-header {
  display: block;
  min-height: 50px;
}

.custom-header-checkbox {
  margin: -19px 0px 0px 0px;
  padding: 0 0 0 0;
}

.custom-table-row {
  td {
    vertical-align: middle;

    .custom-checkbox {
      margin: -5px 0px 0px 0px;
      padding: 0 0 0 0;
    }
  }
}

.custom-dss-table-row {
  td {
    vertical-align: middle;

    select {
      min-width: 85px;
      font-size: 8px;
    }

    input {
      font-size: 8px;
    }

    a {
      font-size: 8px;
    }
  }
}

.btn-black {
  color: #29363d;
}

.login-img {
  height: 100%;
  background-image: url('/assets/img/login.jpg');
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn-lang {
  margin: 0 5px;
  color: #fff;
  background: #20a8d8;
  border-color: #20a8d8;

  &:hover {
    color: #fff;
    background-color: #1b8eb7;
    border-color: #1985ac;
  }
}

.action-col {
  text-align: center;
  position: relative;
  min-width: 100px;
}

.action-col > span {
  position: absolute;
  left: 0;
  /* vertically center the icon */
  top: 50%;
  transform: translateY(-50%);
}

.action-icon {
  margin: 0px 5px;
  font-size: 18px;
}

.action-icon-copy {
  margin: 0px 5px;
  font-size: 18px;
  color: $mediumgrey;
}

.custom-loader {
  margin: 325px auto;
}

.custom-modal-loader {
  display: flex;
  width: 100%;
  height: 30vh;
  align-items: center;
}

.page404-admin {
  margin-top: 20%;
}

.time-divider {
  vertical-align: middle !important;
  padding: 0 !important;
}

input[name='startHour'],
input[name='endHour'] {
  text-align: right !important;
}

.dropzone .dz-message {
  color: #20a8d8 !important;
  padding: 2.25rem 0;
  margin-top: 0 !important;

  & span:before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%2320a8d8' d='M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z'/%3E%3C/svg%3E") !important;
  }
}

.dz-upload {
  color: $primary;
  font-size: 1rem;

  i {
    cursor: pointer;
  }
}

.dropzone .ReactCrop {
  margin: 1rem;
  border-radius: 0.3rem;

  img {
    max-height: 18rem !important;
  }
}

.dropzone.img-dropzone {
  max-width: fit-content;
  max-height: fit-content;
}

.dropzone {
  border-color: #20a8d8 !important;

  :focus {
    outline: none;
  }
}

.tooltip-symbol {
  cursor: pointer;
  margin-left: 4px;

  &:after {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Font Awesome 5 Pro';
    content: '\f05a';
    color: $mediumgrey;
  }
}

.modal-md {
  min-width: 800px;
}

.modal-lg {
  min-width: 1000px;
}

.modal-xl {
  min-width: 1200px;
}

.modal-help-text {
  color: $darkgrey;
  font-size: 14px;
  display: block;
  width: 100%;
  padding: 5px;
  font-weight: 400;
}

.dark-grey {
  color: $darkgrey
}

.modal-setting-title {
  color: $darkgrey;
  font-size: 14px;
  display: block;
  width: 100%;
  padding: 5px;
  font-weight: 400;
}

.checkbox {
  margin: 25px 0px;
}

.remove-padding {
  padding: 0.75rem 0.1rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.tooltip-inner > ol {
  text-align: left;
}

.audio-player-talbe {
  thead th {
    background: #fff;
  }

  .audio-file-data {
    .player-column {
      width: 350px;
    }

    tr td {
      background: #fff;
      vertical-align: middle;
    }
  }
}

.override-danger {
  color: $danger !important;
}

a {
  &:hover {
    text-decoration: none;
  }
}

.align-input-fields {
  height: 120px;
}

.sound-wave {
  width: inherit;
  margin: 10px 0px;
  border: 2px solid;
  border-color: #63c2de;
  border-radius: 10px;
}

.dss-button {
  cursor: pointer;

  &:hover {
    fill: #999999;
  }
}

.countries-container {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

/* custom collapse style */
.collapse-header {
  //background: #d6e5ef;
  /* cursor: pointer !important; */
  /*&:hover {
    background: #96c1de;
  }*/
  .title {
    float: left;
    width: auto;
    padding: 8px 0px 0px 20px;
  }

  .actions {
    float: right;
  }
}

/*.collapse-open {
  background: #f7f7f7;
}*/

.select-popover-style {
  display: block;
  margin: -35px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: 35px;
}

.cdr-details {
  .CodeMirror {
    height: 800px;
  }
}

.btn-add-data {
  display: block;
  margin: 0 auto;
}

/* Card Layout/Sub Menu component style */

.card {
  .sub-menu-header {
    padding: 0;
    margin: 0;
    color: $gray-900;
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  .sub-menu-icon {
    float: right;
    color: #d8d8d8;
  }
}

label {
  text-transform: uppercase;
  color: $primary;
  font-size: 1.25rem;
}

.form-group,
.card {
  input,
  select,
  textarea,
  .rbt-input-multi {
    // Multiple select field color
    background-color: $inputFieldBgColor;

    // Selected items color
    .rbt-token {
      background-color: #c6e8ff;

      &:active {
        background-color: #1f8dd6;
      }
    }
  }
}

// Email input field styles
.react-multi-email {
  background-color: $inputFieldBgColor;
  border-radius: 0px 4px 4px 0px;
  border-color: $gray-200;

  // Placeholder text styles
  span {
    color: $gray-600 !important;
  }

  input {
    &:focus {
      background-color: $white !important;
    }
  }
}

.pagination {
  padding-top: 20px;
}

/* Dropdown borders*/
.dropdown-menu {
  box-shadow: 0px 0px 4px $lightgrey;
  border-color: $xlightgrey;
}

.btn-outline-warning {
  background-color: transparent;

  &:hover {
    color: $white !important;
  }
}

.badge-light {
  color: $black !important;
}

.btn-warning,
.btn-warning:hover {
  color: $white;
}

/* Secondary button style */
.btn-secondary {
  color: $accent !important;
  border-color: lighten($color: $accent, $amount: 40) !important;
  background-color: $white !important;
  font-weight: 400;

  &:hover {
    color: $accent !important;
    border-color: $accent !important;
    background-color: $white !important;
  }

  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $white !important;
    border-color: $accent !important;
    background-color: $accent !important;
  }

  i {
    font-size: 1rem;
  }
}

.dashboard-welcome-label {
  margin-left: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 1rem;

  h1 {
    color: $accent;
    font-weight: 200;

    span {
      text-transform: capitalize;
    }
  }

  .btn-link {
    color: $mediumgrey;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 400;
    margin-left: 0.5rem;
    font-size: small;
  }
}

.async-table-search-button {
  .input-group-text {
    border: none;
    background: $orange-gradient;
    color: $white;
  }
}
.bottom-0 {
  bottom: 0;
}
.template-download-message {
  border: 3px dashed blue;
  border-left: none;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  padding-right: 5px;
}
.template-download-icon {
  width: 40px;
  height: 55px;
  background-color: #002583;
  padding-left: 15px;
  padding-top: 17px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  color: white;
  margin-right: 5px;
}
.ims-side-info {
  background-color: $navbar-brand-bg;
  padding-top: 1.75rem;
  text-transform: uppercase;
}
.ims-side-info-txt-normal {
  color: #000000;
  font-size: 14px;
  font-weight: 200;
}
.ims-hr-custom {
  height: 2px;
  border: none;
  background-color: #d8d8d8;
}
.ims-checkbox-custom-size {
  height: 20px;
  width: 20px;
  margin-bottom: 5px;
}
.dynamic-max-height {
  max-height: calc(100vh - 320px);
  overflow: auto;
}
.ims-file-cancel {
  height: 20px;
  color: #f0f3f5;
  width: 20px;
  padding-left: 6px;
  background-color: red;
  padding-top: 3px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #a90000;
  }
  &:active {
    background-color: #ff5454;
  }
}
.ims-no-records {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20%;
  color: #f9021a;
  font-size: 16px;
  font-weight: bold;
}
.ims-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  background-color: $navbar-brand-bg;
  left: 0;
}
.ims-search-btn {
  background: linear-gradient(to right, #f6ac3b, #f07825);
  border-radius: 5px;
  color: #ffffff !important;
}
.ims-side-info-txt-imp {
  color: #002583;
  font-size: 18px;
  font-weight: bold;
}
.ims-detail {
  width: 65%;
  border-radius: 2px;
  border: 2px dashed #002583;
}

.label-small {
  font-size: 1rem;
}

.statistics-range-picker-title-row {
  margin-top: 2em;
}

.statistics-range-picker-logo {
  margin-top: -2em;
}

@media only screen and (max-width: 1350px) {
  .statistics-range-picker-logo {
    opacity: 0;
  }
}

.statistics-range-picker-previous-period-row {
  margin-top: -5em;
}

.statistics-label-text {
  text-transform: none;
  color: white;
}

.numbered-statistics-title {
  font-size: 0.85em;
}

.numbered-statistics-value {
  font-size: 2.1em;
  font-weight: bold;
}

.numbered-statistics-percentage {
  margin-top: 0.2em;
  align-self: right;
}

.numbered-statistics-percentage {
  opacity: 0;
}
.queue-chart-widget:hover .numbered-statistics-percentage {
  opacity: 1;
}

.statistics-icon {
  margin-top: 0.2em;
}

.statistics-icon-and-percentage {
  display: flex;
  float: right;
  justify-content: space-between;
  align-content: flex-end;
  margin-left: auto;
  margin-right: 3em;
}

.fa-chevron-circle-up {
  margin-top: 5px;
}

.numbered-statistics-icon-up {
  color: green;
  font-size: 1.4em;
  margin-top: 0.6em;
}

.live-statistics-table-call-direction-icon {
  color: #63c2de;
  margin-right: 0.5em;
  // font-size: 1.4em;
}

.numbered-statistics-icon-down {
  color: red;
  font-size: 1.4em;
  margin-top: 0.6em;
}

.pie-chart-legend-statistics-icon-up {
  color: green;
  font-size: 1.4em;
  margin-top: 0.2em;
}

.pie-chart-legend-statistics-icon-down {
  color: red;
  font-size: 1.4em;
  margin-top: 0.2em;
}

.pie-chart-legend-dot-icon {
  font-size: 0.7em;
  padding-top: 1em;
}

.async-table-card-filter,
.async-table-card-content {
  .form-group {
    margin-bottom: 0;
  }

  .async-table-style {
    margin-bottom: 0;

    .custom-checkbox {
      margin-top: -0.75rem;
    }
  }
}

.async-table-card-filter {
  margin-bottom: 0;
  border-radius: 2rem 2rem 0 0;
  border: 0;
  border-bottom: 1px solid #c8ced3;
  .row {
    gap: 1.5rem;
  }
  .col-xl-4 {
    flex: 0;
    padding: 0;
    .input-group {
      min-width: 15rem;
      margin-left: 1rem;
    }
  }
  .col-xl-8 {
    padding: 0;
    display: flex;
    justify-content: flex-start;
  }
}

.async-table-card-content {
  border-radius: 0 0 2rem 2rem;
  >.card-body {
    padding-top: 0;
  }
  th {
    font-weight: bold;
  }
}

.voicemail-messages-table .async-table-card-filter .btn-danger::after {
  content: 'Delete';
  margin-left: .5rem;
}

.dashboard-accordion {
  border-bottom: 1px solid $lightgrey;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  &:last-child {
    border-bottom: unset;
  }

  .dashboard-modal-category {
    display: inline-table;
    margin-left: 1rem;

    .title-text {
      color: $mediumgrey;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .form-check-label {
      margin-top: 1rem;
    }

    label {
      margin-bottom: 0;

      span {
        color: $primary;
        font-size: 16px;
        font-weight: 400;
        text-transform: none;
      }
    }
  }

  .icon-active,
  .icon-inactive {
    vertical-align: middle;
    font-size: 40px;
    cursor: pointer;
  }

  .icon-active {
    color: $dashboard-configure-checkbox-color;
  }

  .icon-inactive {
    color: $lightgrey;
  }
}

.custom-checkbox {
  label {
    cursor: pointer;

    span {
      padding-left: 1rem;
    }

    &::before,
    &::after {
      top: 0.2rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

/* Primary switch styles */
.switch-primary {
  .switch-input:checked + .switch-slider {
    background-color: $accent !important;
    border-color: darken($accent, 10%);

    &::before {
      border-color: darken($accent, 10%);
      background-color: $white;
    }
  }
}

.switch-slider {
  &::before {
    background-color: $accent;
    border-color: darken($accent, 10%);
  }
}

.device-grid-item {
  height: 100%;
  display: flex;

  .card {
    width: 100%;
  }

  .name {
    color: $darkgrey;
    font-size: 0.875rem;
  }

  .value {
    color: $accent;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .dropdown-menu {
    left: 40px !important;
  }

  .btn-settings {
    display: block;
    width: 1.25rem;
    color: $mediumgrey;
    text-align: center;
    cursor: pointer;
  }

  .title {
    position: relative;
    z-index: 1;
    font-size: 1.25rem;
  }

  .icon-device {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    font-size: 3rem;
    color: rgba(#002583, 0.1);
    z-index: 0;
  }

  .icon-success {
    font-size: 1.5rem;
    color: $green;
    margin-right: 0.25rem;
    vertical-align: top;
  }
}

.app-header {
  flex-wrap: nowrap;

  .navbar-brand {
    width: 220px;
    flex-shrink: 0;
  }
}

.hover-change {
  &:hover {
    .show-default {
      display: none;
    }

    .show-hover {
      display: inline-block;
    }
  }

  .show-hover {
    display: none;
  }
}

.dss-link {
  display: block;
  margin-top: 0.5rem;
  font-size: 1rem;
}

.wakeup-default {
  .checkbox-simple {
    display: inline-block;
  }

  .tooltip-symbol::after {
    vertical-align: top;
  }
}

::placeholder {
  color: $placeholder-color !important;
  opacity: 1; //Firefox
  font-style: italic;
}

.flag-icon-an {
  /* the npm package does not contain Netherlands Antilles  */
  background-image: url('../assets/img/flags/an-4x3.svg');

  &.flag-icon-squared {
    background-image: url('../assets/img/flags/an-1x1.svg');
  }
}

.toggle-button {
  background: transparent;
  border: 0;
  bottom: 0;
  padding: 0 12px;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.8rem;
  color: #575757;
  /* inverted AsideWidget sets the color of the toggle to white */
}

.bg-gradient {
  background-image: url(../assets/img/statistics-background.svg);
  background-repeat: no-repeat;
  background-size: cover;

  .input-range__track--active {
    background: $warning;
  }

  .input-range__slider {
    background: $warning;
    border: 1px solid $warning;
  }

  label {
    display: block;
    margin: auto;
    margin-bottom: 5px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

    span {
      display: block;
      width: 255px;
      margin: auto;
      color: $white;
      font-size: 1rem;
      text-transform: capitalize;

      input {
        width: 70px !important;
        display: inline-block;
        margin-left: 10px;
        margin-right: 15px;
        background-color: $white;
      }
    }
  }
}

.integration-container {
  .card-body {
    padding: 0;
  }

  .black {
    color: $black !important;
  }

  .integrations-logos {
    padding: 1.25rem;
    text-align: center;
    margin: 20px 0;

    .integration-logo {
      max-width: 200px;
      height: auto;
      margin: auto;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    img {
      max-width: 200px;
      height: auto;
      display: block;
      margin: 1rem auto;

      &.telsmart {
        margin-top: 0;
      }

      // Resize Poritma to visually match more closely to TS logo
      &.portima {
        max-width: 8.75rem;
      }
    }

    i {
      color: $primary;
      font-size: 2.5rem;
      transform: rotate(90deg);
    }
  }

  .integrations-section {
    padding: 1.25rem;
    padding-bottom: 25px;
    border-bottom: 1px solid $accent;
    text-align: center;

    &:first-child {
      margin-top: 25px;
    }

    p {
      color: $bluegrey;

      &:last-child {
        margin-bottom: 0;
      }
    }

    span {
      text-decoration: underline;
      text-decoration-style: dashed;
      text-decoration-color: $lightgrey;
    }

    ul,
    li {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    &.integrations-sharing {
      text-align: left;

      h3 {
        font-size: 1.2rem;
        color: $bluegrey;
        text-transform: uppercase;
        margin-bottom: 15px;
      }

      li {
        color: $black;

        i {
          color: $darkgrey;
          margin-right: 10px;
        }
      }
    }

    .integrations-deny {
      color: $bluegrey;
      display: block;
      margin-top: 15px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.live-statistics-label-text {
  text-transform: none;
  color: white;
}

.integrations-message {
  margin-top: 150px;
  margin-left: 25px;
  color: $bluegrey;

  &.success {
    text-align: center;
    margin-left: 0;
  }

  .black {
    cursor: pointer;
  }
}

.fullscreen-button {
  position: absolute;
  right: 10px;
  z-index: 1;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1020;
  width: 100%;
  height: 100%;
  padding: 0;

  .diagram-container {
    height: 100%;
    border-radius: 0;
  }
}

.primary-color {
  color: $primary;
}

.col-lg-6 .input-group select {
  border-radius: 3px;
  border: 1px solid $gray-200;

  &.disabled {
    background-color: #e4e7ea;
  }
}

.rtv-checkbox {
  font-size: 16px;
  user-select: none;
  min-height: 320px;
  padding: 20px;
  box-sizing: content-box;
}

.rtv-checkbox .tree,
.rtv-checkbox .tree-node,
.rtv-checkbox .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.rtv-checkbox .tree-branch-wrapper,
.rtv-checkbox .tree-node__leaf {
  outline: none;
}

.rtv-checkbox .tree-node {
  cursor: pointer;
}

.rtv-checkbox .tree-node .name:hover {
  background: rgba(0, 0, 0, 0.1);
}

.rtv-checkbox .tree-node--focused .name {
  background: rgba(0, 0, 0, 0.2);
}

.rtv-checkbox .tree-node {
  display: inline-block;
}

.rtv-checkbox .checkbox-icon {
  margin: 0 5px;
  vertical-align: middle;
}

.rtv-checkbox button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.rtv-checkbox .arrow {
  margin-left: 5px;
  vertical-align: middle;
}

.rtv-checkbox .arrow--open {
  transform: rotate(90deg);
}

tr:has(div.mark-parent-row) {
  background-color: rgba($orange, 0.1);
}

.fw-normal{
  font-weight: normal !important;
}

.modal-setting-title.modal-settings-title-appswitch {
  width: unset;
  display: inline-block;
  top: -8px;
  position: relative;
}

.opacity-50{
  opacity: 0.5;
}