.srd-port {
  width: 15px;
  height: 15px;
  background: rgba(white, 0.1);
  border: solid 1px #c2cfd6;
  border-radius: 0px;

  &:hover,
  &.selected {
    background-color: #c2cfd6;
  }
}
.srd-node--selected {
  .srd-default-node {
    .srd-default-node__ports {
      .srd-default-node__in {
        .srd-default-port {
          .srd-port {
            border-color: #6d6d6d;
          }
        }
      }

      .srd-default-node__out {
        .srd-default-port {
          .srd-port {
            border-color: #6d6d6d;
          }
        }
      }
    }
  }
}

.srd-default-port--in {
  .srd-port {
    margin: 5px 5px 5px -1px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.srd-default-port--out {
  .srd-port {
    margin: 5px -1px 5px 5px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
