.audio-player {
  display: inline-flex;
  height: 50px;
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  border-radius: 1.5rem;
  background: linear-gradient(to right, #07a2f0, #058bce);
  color: $white;
  font-size: 0.8rem;
  padding: 0 0.5rem;
  align-items: center;

  div,
  input {
    margin-right: 0.5rem;
    cursor: pointer;
  }

  input {
    // Override native appearance
    appearance: none;
    background: none !important;
    width: 100%;

    // Chrome & Safari styles
    &::-webkit-slider-runnable-track {
      border-radius: 1.5rem;
      height: 0.35rem;
      background-color: #9ee4ff;
    }

    &::-webkit-slider-thumb {
      appearance: none;
      width: 0.85rem;
      height: 0.85rem;
      background: #3f50bb;
      border: 1px solid $black;
      border-radius: 50%;
      margin-top: -0.25rem;
    }

    // Firefox styles
    &::-moz-range-track {
      border-radius: 1.5rem;
      height: 0.35rem;
      background-color: #9ee4ff;
    }

    &::-moz-range-thumb {
      width: 0.85rem;
      height: 0.85rem;
      background: #3f50bb;
      border: 1px solid $black;
      border-radius: 50%;
    }
  }

  .player-volume {
    width: 30%;
  }

  .extra-option {
    margin-right: 0.4rem;
    padding: 0 5px;
    cursor: pointer;
  }
}
