.srd-diagram {
  position: relative;
  flex-grow: 1;
  display: flex;
  cursor: move;
  overflow: hidden;

  &__selector {
    position: absolute;
    background-color: rgba(0, 192, 255, 0.2);
    border: solid 2px rgb(0, 192, 255);
  }
}

.srd-default-node__name {
  .card {
    margin-bottom: 0;
    border: 0px !important;
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .card-header {
      text-transform: unset;
      background: #20a8d8;
      color: #fff;
      border: 0px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      font-weight: 700;
      font-size: 16px;
      padding: 0.25rem 1.25rem;

      & > i {
        font-size: 22px;
      }
    }

    .card-body {
      padding: 0.7rem;
    }
  }
}

.btn-diagram-edit {
  width: 30px;
  height: 38px;
  position: absolute;
  right: 61px;
  top: 1px;
  margin-top: 0px !important;
  border-radius: 0px;

  i {
    margin-left: -8px;
  }
}

.btn-diagram-duplicate {
  width: 30px;
  height: 38px;
  position: absolute;
  right: 31px;
  top: 1px;
  margin-top: 0px !important;
  border-radius: 0px;

  i {
    margin-left: -8px;
  }
}

.btn-diagram-close {
  width: 30px;
  height: 38px;
  position: absolute;
  right: 1px;
  top: 1px;
  margin-top: 0px !important;
  border-radius: 0px;
  border-top-right-radius: 20px;

  i {
    margin-left: -7px;
  }
}

.blue {
  .card {
    .card-header {
      background-color: #20a8d8;
      color: #ffffff !important;

      .btn-diagram-edit,
      .btn-diagram-duplicate,
      .btn-diagram-close {
        background-color: #20a8d8;
        border: 1px #20a8d8 solid;

        &:hover {
          background: #117ea5;
        }
      }
    }
  }
}

.purple {
  .card {
    .card-header {
      background-color: #8f00c9;
      color: #ffffff !important;

      .btn-diagram-edit,
      .btn-diagram-duplicate,
      .btn-diagram-close {
        background-color: #8f00c9;
        border: 1px #8f00c9 solid;

        &:hover {
          background: #610d84;
          outline: none;
        }
      }
    }
  }
}

.green {
  .card {
    .card-header {
      background-color: #24a121;
      color: #ffffff !important;

      .btn-diagram-edit,
      .btn-diagram-duplicate,
      .btn-diagram-close {
        background-color: #24a121;
        border: 1px #24a121 solid;

        &:hover {
          background: #1a7718;
        }
      }
    }
  }
}

.orange {
  .card {
    .card-header {
      background-color: #f17725;
      color: #ffffff !important;

      .btn-diagram-edit,
      .btn-diagram-duplicate,
      .btn-diagram-close {
        background-color: #f17725;
        border: 1px #f17725 solid;

        &:hover {
          background: #d55e0e;
        }
      }
    }
  }
}

.yellow {
  .card {
    .card-header {
      background-color: #f5af36;
      color: #ffffff !important;

      .btn-diagram-edit,
      .btn-diagram-duplicate,
      .btn-diagram-close {
        background-color: #f5af36;
        border: 1px #f5af36 solid;

        &:hover {
          background: #c78615;
        }
      }
    }
  }
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

.tbr-day {
  display: inline-block;

  & span {
    margin-right: 2px;
    font-size: 10px;
    font-weight: 500;
  }
}
.tbr-time {
  display: inline-block;
  text-align: right;

  & span {
    font-size: 11px;
  }
}
