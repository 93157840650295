.srd-default-node {
  background-color: rgb(255, 255, 255);
  border: solid 1px #c2cfd6;
  border-radius: 20px;
  padding-bottom: 15px;
  overflow: visible;

  &__title {
    display: flex;
    white-space: nowrap;

    > * {
      align-self: center;
    }
    .far {
      padding: 5px;
      cursor: pointer;
    }
  }

  &__name {
    flex-grow: 1;
    border-radius: 20px;
  }

  &__ports {
    display: flex;
    // background-image: linear-gradient(rgba(black, 0.1), rgba(black, 0.2));
  }

  &__in,
  &__out {
    flex-grow: 1;
    flex-direction: column;
  }
}
