// Styles
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~@fortawesome/fontawesome-pro/css/all.css';
// React multi email style
@import '~react-multi-email/style.css';
// Import Main styles for this application
@import './scss/style.scss';
// Diagram styles
@import './scss/diagram/style.scss';
// Rsuites npm module styles
@import "~rsuite/dist/rsuite.css";