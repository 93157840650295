.queue-chart-widget {
  p {
    color: $darkgrey;
    font-size: small;
    font-weight: normal;
  }

  .submit {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .checkbox-simple {
    margin: 0.5rem 0 0 0.25rem;

    label {
      font-size: 1rem;
    }
  }

  .btn-orange-gradient {
    background: $orange-gradient;
    border-color: #f6ac3b !important;
  }
}
