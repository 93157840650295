.call-stats-chart-widget {
  p {
    color: $darkgrey;
    font-size: small;
    font-weight: normal;
  }

  .btn-toolbar {
    float: right;
    margin: 0.5rem 1rem 0 0;
  }

  .row {
    margin-bottom: 1rem !important;
    text-align: center;

    .col-md {
      div {
        color: $darkgrey;
        font-size: small;
        text-transform: uppercase;
      }
    }
  }

  .progress {
    margin-top: 0.5rem;
  }
}
